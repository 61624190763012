<template>
  <v-container fluid>
    <!-- Section Path File/Modul -->
    <v-breadcrumbs
      :items="pathModul"
      divider="-"
      normal
      class="pathModul"
    ></v-breadcrumbs>

    <!-- Section Filtering Input/Select/Button -->
    <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'" >
      <v-row align="center">
        <!-- Column Select Station -->
        <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'" class="col-input-filter">
          <v-select
            v-on:change="selectValueStation"
            :items="itemStation"
            label="Station"
            prepend-inner-icon="mdi-store-marker"
            hide-details
            solo
            dense
          ></v-select>
        </v-col>

        <!-- Column Select Month -->
        <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'" class="col-input-filter">
          <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            v-model="modalCalendar"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormat"
                label="Date"
                prepend-inner-icon="mdi-calendar-month"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                solo
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-on:change="selectDate"
              v-model="dateFormat"
              @input="modalCalendar = false"
              type="month"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Generate Button -->
        <v-col cols="12" sm="6" md="4">
          <v-btn
            class="white--text btn-generate button mr-2 mt-3"
            solo
            dense
            normal
            @click="GenerateTable"
            :disabled="valStation==null"
          >
            GENERATE REPORT
          </v-btn>

          <v-btn
            class="white--text btn-upload button mt-3"
            solo
            dense
            normal
            @click="UploadForm"
          >
            UPLOAD FILE
          </v-btn>

        </v-col>
      </v-row>

      <v-card class="pa-4" style="max-height: calc(100vh - 230px);overflow-y:auto;">

        <v-subheader class="subTitle black--text font-weight-bold">
          Maintenance Record For {{ stationID }} ({{ dateSelected }})
        </v-subheader>

         <!-- Table -->
        <v-data-table
          :headers="thead_maintenance"
          :items="tbody_maintenance"
          :header-props="{ sortIcon: null }"
          class="elevation-1 tableMaintenance headerDtSarawak"
          :items-per-page="5"
          no-select-on-click
          :custom-sort="customSort"
        >
          <template v-slot:[`item.datetimeUpload`]="{ item }">
            <!-- {{ item.datetime_a }} -->
            <span v-html="item.datetimeUpload"></span>
          </template>
          <template v-slot:[`item.fileDoc`]="{ item }">
            <v-icon
              color="#02b2c3"
              v-html="item.fileDoc"
              @click="openFileDoc(item)"
            >
            </v-icon>
          </template>
          <template v-slot:[`item.delDetails`]="{ item }">
            <v-icon
              :disabled="userCanUse"
              color="red"
              v-html="item.delDetails"
              @click="deleteItem(item)"
            >
            </v-icon>
          </template>
        </v-data-table>
      </v-card>

    </v-container>


    <!-- Section Upload File (TABLE) -->
    <v-container class="container-upload-file form-uploadFile"> </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
// import Papa from "papaparse";

export default {
  data: () => ({
    pathModul: [
      {
        text: "Home",
        disabled: false,
        href: "/",
        // href: '/Mapviewer',
      },
      {
        text: "Report - Maintenance Record",
        disabled: true,
        href: "/Operational/Maintenance",
      },
    ],
    itemStation: [],
    dateFormat: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 7),
    menu: false,
    modalCalendar: false,
    menu2: false,

    // Variable/Object for value filtering
    valStation: null,
    valDate: null,

    // Title - Subtitle After Generate Data
    stationID: null,
    dateSelected: null,
    monthText: [
      "Null",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    // DataTable
    thead_maintenance: [
      {
        text: "STATION ID",
        value: "stationId",
        divider: true,
        align: "center",
        sortable: false,
      },
      {
        text: "DATE OF MAINTENANCE",
        value: "datetimeUpload",
        divider: true,
        align: "center",
      },
      { text: "UPLOADER", value: "uploader", divider: true, align: "center" },
      {
        text: "DOCUMENT",
        value: "fileDoc",
        divider: true,
        align: "center",
        sortable: false,
      },
      // {text: "Document",value: "", divider: true, align: "center"},
      {
        text: "DELETE",
        value: "delDetails",
        divider: true,
        align: "center",
        sortable: false,
      },
    ],
    tbody_maintenance: [],

    bodyDeleteBtn: { text: "Actions", value: "delDetails", sortable: false },
  }),

  methods: {
    convertDateFormat(date) {
      var dd = date.split("-")[2];
      var mm = date.split("-")[1];
      var yyyy = date.split("-")[0];
      date = dd + "/" + mm + "/" + yyyy;
      return date;
    },

    load() {
      this.getStation();
    },

    getStation() {
      axios
        .get(this.globalUrl+"bakaj/stations", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.itemStation = [];

          for (let i = 0; i < response.data.length; i++) {
            this.itemStation.push(
              response.data[i].stationId + " - " + response.data[i].location
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Get value from Selected Station
    selectValueStation: function(e) {
      // console.log(e);
      this.valStation = e;
    },

    // Get value from Selected Date
    selectDate: function(e) {
      this.valDate = e;
    },

    getDataMaintenance(station, year, month) {
      axios
        .get(
          this.globalUrl+"maint/data?stationid="+station + "&year=" + year +"&month=" + month,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          let displayData = [];

          for (let i = 0; i < response.data.length; i++) {
            response.data[i]["datetimeUpload"] = this.convertDateFormat(
              response.data[i]["datetimeUpload"]
            );
          }

          response.data.forEach(function(res) {
            res["fileDoc"] = "mdi-file-document";
            res["delDetails"] = "mdi-delete";
            displayData.push(res);
          });
          this.tbody_maintenance = displayData;
          console.log(this.tbody_maintenance);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openFileDoc: function(e) {
      console.log(e);
      // window.location.href = e.filepath;
      window.open(e.filepath, "_blank");
    },

    deleteItem: function(e) {
      console.log(e);
      axios
        .get(this.globalUrl+"maint/del?id=" + e.id, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          console.log("Success Deleted");
          this.GenerateTable();
        })
        .catch((error) => {
          console.log(error);
          console.log("Unsuccess...");
        });
    },

    GenerateTable() {
      // Keluarkan Details SubText
      this.stationID = null;
      this.stationID = this.valStation.split(" - ")[0];
      for (let i = 0; i < this.monthText.length; i++) {
        if (i == this.dateFormat.split("-")[1]) {
          this.dateSelected =
            this.monthText[i] + " " + this.dateFormat.split("-")[0];
        }
      }

      // Unhide checkbox on header
      document.querySelector(".subTitle").style.display = "block";
      let chk = document.querySelectorAll(".checkbox-header-dailyReport");
      for (let i = 0; i < chk.length; i++) {
        chk[i].style.display = "contents";
      }

      this.getDataMaintenance(
        this.stationID,
        this.dateFormat.split("-")[0],
        this.dateFormat.split("-")[1]
      );
    },

    UploadForm: function() {
      window.location.href = "/Operational/MaintenanceUpload";
    },

    customSort: function(items, index, isDesc) {

      items.sort((a, b) => {
        if (index[0] == "datetimeUpload") {
          if (!isDesc[0]) {
            return (
              new Date(this.standardDtFormatNuzul(b[index])) -
              new Date(this.standardDtFormatNuzul(a[index]))
            );
          } else {
            return (
              new Date(this.standardDtFormatNuzul(a[index])) -
              new Date(this.standardDtFormatNuzul(b[index]))
            );
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
  },

  mounted() {
    this.load();
  },
};
</script>

<style lang="scss">
@import "~scss/main";

/* Section Path File/Modul */
.pathModul {
  padding-bottom: 0px;
}

.pathModul > li:nth-child(3) > a,
.pathModul > li:nth-child(3) > div {
  color: black !important;
}

/* Section Filtering Input/Select/Button */
.container-filtering {
  padding: 0px;
}

.col-dateYear {
  display: flex !important;
}

.col-dateYear > i {
  margin-right: 9px !important;
}

.btn-download-table {
  margin-right: 8px;
}

.column-upload-button {
  padding: 0px 0px 10px 0px !important;
}

/* Section Generate Result Datatable Vue */
.subTitle {
  text-align: center !important;
  background: white;
  // color: #1976D2 !important;
  display: none;
}

.form-datatable {
  padding: 10px 0px 0px 0px;
}

// .tableMaintenance > div:nth-child(1) > table > thead > tr > th{
//     background: #4495D1;
//     color: white !important;
//     font-weight: bold;
// }

.tableMaintenance > .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 550px;
}

/* Section Upload File */
.form-uploadFile {
  display: none;
}
</style>